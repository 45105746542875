<template>
  <div  style="margin-left: 10%;margin-right: 10%;
                display: flex;flex-direction: row;justify-content: center;">
    <div v-for="item in datalist" :key="item.index"
         style="display: flex;flex-direction: column;justify-content: center;
             align-items: center;
             width:20rem ; height: 20rem">
      <div style="width:50%;height:30%;
           display: flex;align-items: center;flex-direction: row;
                   margin-top: 4rem ">
        <img :src="item.imageurl"
             style="width: 100%;height: 100%;align-items: center">
      </div>
      <div style="font-size: 1.8rem;font-weight: bold;margin-top: 2rem;
        margin-bottom: 1rem;letter-spacing: 0.2rem;
                    color: #2285ff;">
        {{item.title}}
      </div>
      <div style="margin-top: 1rem;margin-bottom: 3rem;
                    display: flex;flex-direction: column;
                    font-size: 1.2rem;
                   align-items: center;color: #686868;">
        <p v-for="citem in item.list" :key="citem.index"
           style="padding: 0.2rem;margin: 0" v-html="citem">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'logotitlebox',
  props: ['datalist'],
};
</script>

<style scoped>

</style>
