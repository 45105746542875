<template>
  <div>
    <leaflet-panel
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :show-detail-btn="showDetailBtn"
        :title="title"
    />
<!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`产品优势`"
               :en_title="`Product Advantages`"></Paragraph>
    <Logotitleboxb :datalist="datalistx" ></Logotitleboxb>
    <Logotitleboxb :datalist="datalistx1"></Logotitleboxb>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`产品特色`"
               :en_title="`Feature of Product`"></Paragraph>

    <Logotitleboxb :datalist="datalistx2" ></Logotitleboxb>
    <Logotitleboxb :datalist="datalistx3" style="margin-bottom: 4rem"></Logotitleboxb>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`应用场景`"
               :en_title="`Application Scenarios`"></Paragraph>

    <div style="display: flex;flex-direction: row;margin-left: 10%;margin-right: 10%">
      <Lettertitleblock :datalist="datalistl"
                        style="display: flex;flex-direction: row;justify-content:center ;
                        width: 100%"></Lettertitleblock>
    </div>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`应用场景`"
               :en_title="`Cases `"></Paragraph>

    <Stitlestrlist :datalist="datalist" style="margin-left: 10%;margin-bottom: 4rem">
    </Stitlestrlist>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Logotitleboxb from '@/components/chain/basic/common/logotitleboxb';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';
import Stitlestrlist from '@/components/chain/basic/common/stitlestrlist';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Logotitleboxb,
    Lettertitleblock,
    Stitlestrlist,
  },
  data() {
    return {
      datalist: [
        { title: '>', content: '爱心房产' },
        { title: '>', content: '浙江绿迹' },
      ],
      datalistl: [
        {
          title: '供应链金融场景',
          imgsrc: 'A',
          list: [
            '合同、仓单、票据、采购单等',
            '数据上链，数据更可信，融资更高效',
            '电子数据一旦存证，不可篡改，不可抵赖',
            '有效减少纠纷的发生',
            '高效维护融资双方权益',
          ],
        }, {
          title: '电子合同场景',
          imgsrc: 'B',
          list: [
            '对电子合同全流程中的',
            '注册、实名、数字证书发放、签约意愿确认、',
            '电子合同签署等环节存证固化',
            '同步司法机构，形成完整可信的证据链',
          ],
        }, {
          title: '知识产权保护场景',
          imgsrc: 'C',
          list: [
            '对版权作品加盖时间戳、并且上链存证',
            '实现快速确权',
            '侵权网页可固化成有效证据',
            '存证记录直连法院司法链',
            '可有效提高维权效率',
          ],
        }, {
          title: '电商维权场景',
          imgsrc: 'D',
          list: [
            '对电商的购买过程通过录屏存证',
            '对商品开箱、寄送品牌方检测中心等过程则用',
            '拍摄视频固化存证',
            '开具司法鉴定报告',
            '可于法院一键调证，高效化解纠纷',
          ],
        },
      ],
      datalistx3: [
        {
          imageurl: require('@/assets/image/contentsvg/db.svg'),
          title: '多链协同',
          list: [
            '支持多链管理',
            '包括链间验证与交易执行等',
            '并可组合轻量公链模式',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/tikit2.svg'),
          title: '低成本易维护',
          list: [
            '可进行轻量网关节点部署',
            '合约代码可复用、升级、本地化测试',
            '&nbsp;',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/people.svg'),
          title: '强安全和隐私保护',
          list: [
            '提供多种隐私保护能力算法',
            '支持国密算法等多套密码体系',
            '&nbsp;',
          ],
        },
      ],
      datalistx2: [
        {
          imageurl: require('@/assets/image/contentsvg/box.svg'),
          title: '积木化定制',
          list: [
            '共识、账本、合约、存储各自独立',
            '采用标准接口，灵活切换密码算法',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/car.svg'),
          title: '高性能',
          list: [
            '全新底层架构，交易处理达2万TPS',
            '交易确认为秒级，支持海量存储',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/note.svg'),
          title: '有效数据治理',
          list: [
            '支持业务数据穿透检索、多维分析',
            '独立备份、归档、监管和审计',
          ],
        },
      ],
      datalistx: [
        {
          imageurl: require('@/assets/image/contentsvg/sperker.svg'),
          title: '权威节点见证',
        }, {
          imageurl: require('@/assets/image/contentsvg/tie.svg'),
          title: '安全可靠的技术',
        }, {
          imageurl: require('@/assets/image/contentsvg/book.svg'),
          title: '真实不可篡改',
        },
      ],
      datalistx1: [
        {
          imageurl: require('@/assets/image/contentsvg/people.svg'),
          title: '合规隐私保护',
        }, {
          imageurl: require('@/assets/image/contentsvg/box.svg'),
          title: '丰富应用场景',
        }, {
          imageurl: require('@/assets/image/contentsvg/fly.svg'),
          title: '一站式接入',
        },
      ],
      title: '存证服务',
      body:
          '通过区块链、哈希校验、电子签名、可信时间戳等技术保障电子数据法律效力，'
          + '适用于多种应用场景，支持电子凭证、电子协议、电子合同、订单、邮件、网页等多种形式信息存证的一站式数据存证服务。',
      image: require('@/assets/image/chain/va/evidence/panel.webp'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/va/evidence/11_01.jpg'),
        require('@/assets/image/chain/va/evidence/11_02.jpg'),
        require('@/assets/image/chain/va/evidence/11_03.jpg'),
        require('@/assets/image/chain/va/evidence/11_04.jpg'),
      ],
    };
  },
};
</script>

<style scoped></style>
